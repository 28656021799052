.broadcast_div_main{
    width: 95%;
    margin: auto;
    border-radius: 13px;
    box-shadow: 0 0 10px #ccc;
    padding: 40px 15px 40px;
}

.broadcast_description{
    min-width: 100%;
    min-height: 120px;
    background-color: transparent;
    border-radius: 8px;
    padding: 5px;
}

.broadcast_description:focus{
    outline-width: 2px;
    outline-color: #f5c242;
}

.broadcast_description_error{
     min-width: 100%;
    min-height: 120px;
    background-color: transparent;
    border-width: 1px;
    border-color: red;
    border-radius: 8px;
    padding: 5px;
}

.broadcast_description_error:focus{
    outline-width: 2px;
    outline-color: #f5c242;
}

.broadcast_button{
    margin: auto;
    min-width: 200px;
}

.boradcast_desc_error_text{
    padding-left: 20px;
    color: red;
    margin: 0px !important;
}