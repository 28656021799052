
.card{
  width: 100%;
  padding: 15px;
  text-align: center;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 15px 10px 25px 0px  #d2d2d2;
  background: #fff;
  background-repeat: no-repeat, no-repeat;
  background-position: 120% -5%, 200% -40%;
  background-size: 40%, 80%;
  animation: open .5s;
  margin-bottom: 15px;
}
@keyframes open {
  0%  {background-position: 166% -25%, 220% -69%;}
  100%{background-position: 120% -5%, 200% -40%;}
}

.field{
  margin: 5px;
  display: flex;
  flex-direction: column;
  }


.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  margin-bottom: 25px;
}

.custom-file-upload_slider {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.img-wrap{
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.img-upload:before{
  content: "";
  background-image: url("../Images/user.png");
  background-repeat: no-repeat;
  background-size: 50px;
  background-attachment: fixed;
  background-position: center;
  width: 200px;
  height: 200px;
  border: 2px solid;
  font-size: 10px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.174);
}
.img-upload:hover:before{
 opacity: 1;
}

.name{ 
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
  max-width: 220px;
  overflow-wrap: break-word;
}
.status{
  text-align: center;
  max-width: 220px;
  overflow-wrap: break-word;
}

.save{
  font-weight: 600;
  left: -20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 20px;
  box-shadow: 20px 0px 40px 0px  #ccc;
}

.edit{
  color: #fff;
  width: 180px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}


.fa-instagram{
  position: absolute;
  color: #79f1a4;
  top: 3%;
  right: 2%;
  font-size: 38px;
}
.fa-instagram:hover{
  font-size: 42px;
  color: #caff81;
  transition: all .1s linear;
  cursor: pointer;
}


.profile_flex_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.preview_card_div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 300px;
  max-width: 900px;
  margin-bottom: 15px;
}

.preview_card{
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 8px;
  margin: 5px;
}

.preview_card_img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}

.preview_card_close{
  position: absolute;
  border: none;
  outline: none;
  z-index: 99;
  top: 5px;
  left: 85%;
  padding:4px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 10 #fff;
  background-color: rgba(0, 0, 0, 0.611);
  color: #fff;
}

.sliderPhotoUpload{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sliderImgUpload {
  margin: auto;
  display:flex;
  justify-content:center;
  width: 100%;
}

.sliderImgUpload_flex{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
    background-color: #e9e5e5;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 15px;
}

.form_div_slider_upload{
  width: 100% !important;
}

.slider_upload_details{
  width: 50%;
}

.helper_text_msg{
  font-size: 10px;
  color: rgb(130, 130, 130);
}