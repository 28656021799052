.google_autocomplete{
    width:100%;
     height: 52px;
     border-radius:8px;
     border:1px solid #ccc;
     padding:7.5px 14px;
     font-size:16px;
}

.google_autocomplete:focus{
    outline-width: 2px;
    outline-color: #f5c242;
}

.circular_center_div{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.rc-time-picker-input:focus{
  outline-width: 2px;
  outline-color: #f5c242;
}

.rc-time-picker-input{
  height: 54px !important;
  font-size: 16px !important;
  width: 100% !important;
  min-width: 250px !important;
  border-radius: 8px !important;
  color: #000 !important;
}

.rc-time-picker-clear{
  top: 15px !important;
  font-size: 18 !important;
}