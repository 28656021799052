.video_player_div{
    width: 100%;
    height: 50vh;
    max-width: 1200px;
    display: flex;
}

.video_player_div_flex{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    border: 1px dotted #ccc;
}

.default_upload_img{
    width: 200px;
}

.video_player_flex_button{
    margin-top: 20px;
    display: flex;
    width: 100%;
}

.video_btn{
    margin-right: 10px;
}