.apply_filter_div{
    width: 100%;
    display: flex;
    justify-content:left;
    margin-top: 15px;
    margin-bottom: 15px;
}


.organizer_filter_select{
    width: 80%;
    min-width: 250px;
}

@media (max-width:500px) {
    .organizer_filter_select{
    width: 80%;
    min-width: 150px;
}
}